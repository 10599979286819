.Locations {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding: 50px 5% 0px 5%;
    .LocationsTitle {
        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #0157ff;
        }
        h2 {
            font-size: 15px;
            font-weight: 400;
            color: #252525;
            margin-top: 20px;
        }
    }
    .LocationsLsit {
        margin-top: 40px;
        .LocationsBox {
            border-top: 3px solid #dcdcdc80;
            background-color: white;
            padding: 20px 10px;
            h3 {
                margin-bottom: 20px;
                font-size: 40px;
            }
            h2 {
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #232323;
                text-align: start;
            }
            h4 {
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                color: #000000;
                text-align: start;
                margin-top: 20px;
            }
            a {
                color: #0157ff;
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 790px) {
    .Locations {
    }
}

@media screen and (min-width: 1700px) {
    .Locations {
        padding-right: 13%;
        padding-left: 13%;
    }
}
